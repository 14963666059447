import {
    Alert,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { auth, db } from "../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";

export default function AgreeTerms() {
    const [user, loading, error] = useAuthState(auth);

    const [signInError, setSignInError] = useState(null);
    const [checked, setChecked] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClick = async () => {
        if (checked) {
            await updateDoc(doc(db, "users", user.uid), {
                agree: true,
            });
            navigate("/");
        } else {
            setSignInError("Please accept the Terms and Conditions first.");
        }
    };

    return (
        <>
            <CssBaseline />
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Container
                    sx={{
                        mt: 20,
                        mb: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body" sx={{ fontSize: "20px" }}>
                        I agree to the{" "}
                        <a href="/terms" target="_blank">
                            Terms and Conditions and Privacy Policy.
                        </a>
                    </Typography>
                </Container>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{ width: 200, mb: 4 }}
                >
                    Continue
                </Button>
                {signInError && <Alert severity="error">{signInError}</Alert>}
            </Container>
        </>
    );
}

import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import {
    doc,
    updateDoc,
    addDoc,
    collection,
    query,
    getDoc,
    orderBy,
    where,
    serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    ClickAwayListener,
    CssBaseline,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Select,
    Stack,
    IconButton,
    Container,
    Tooltip,
    Typography,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChecklistItem from "../components/ChecklistItem";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PronounButtons from "../components/PronounButtons";

const palette = {
    pink: "#FFADAD",
    champagne: "#FFD6A5",
    yellow: "#FDFFB6",
    macha: "#CAFFBF",
    lightblue: "#9BF6FF",
    blue: "#A0C4FF",
    purple: "#BDB2FF",
    mauve: "#FFC6FF",
    white: "#FFFFFC",
};

export default function Checklist() {
    const [user, userLoading, userError] = useAuthState(auth);

    useEffect(async () => {
        if (!userLoading) {
            const docRef = doc(db, "users", user?.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.data().agree !== true) {
                navigate("/agree");
            }
        }
    }, [user]);

    const { id: checklistId } = useParams();
    const [comment, setComment] = useState(null);
    const [title, setTitle] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("checklistTitle");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [commentDict, setCommentDict] = useState({});

    const [toggleClear, setToggleClear] = useState(0);

    const handleClear = () => {
        setComment(null);
        setCommentDict({});
        setToggleClear((prevToggleClear) => prevToggleClear + 1);
    };

    const navigate = useNavigate();

    const [value, loading, error] = useDocument(
        user
            ? doc(db, "checklists", checklistId)
            : collection(db, "checklists"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [checklistItems, itemsLoading, itemsError] = useCollection(
        query(
            user
                ? collection(db, "checklist-items")
                : collection(db, "checklist-items"),
            where("checklistId", "==", checklistId),
            orderBy("index")
        )
    );

    const handleTitleChange = async (event) => {
        await setTitle(event.target.value);
        await updateDoc(doc(db, "checklists", checklistId), {
            title: title,
        });
    };

    const handleArrowClick = () => {
        navigate("/");
    };

    const handleAddItem = async () => {
        let newStartingIndex = 0.95 * value.data().startingIndex;
        await addDoc(collection(db, "checklist-items"), {
            checklistId: checklistId,
            content: "Double click here or click the edit icon to change...",
            index: newStartingIndex,
            nextIndex: value.data().startingIndex,
            lastEdit: serverTimestamp(),
        });
        await updateDoc(doc(db, "checklists", checklistId), {
            startingIndex: newStartingIndex,
        });
    };

    const [delimiter, setDelimiter] = useState("space");

    const handleChange = (event, newDelimiter) => {
        setDelimiter(newDelimiter);
    };

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const [nameOpen, setNameOpen] = useState(false);

    const handleNameTooltipClose = () => {
        setNameOpen(false);
    };

    const handleNameTooltipOpen = () => {
        setNameOpen(true);
    };

    const [name, setName] = useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const [pronouns, setPronouns] = useState("he");

    const handlePronounChange = (event) => {
        setPronouns(event.target.value);
    };

    const updateComment = () => {
        setComment((prevComment) => prevComment.replaceAll("*name*", name));
        if (pronouns === "he") {
            setComment((prevComment) =>
                prevComment.replaceAll("*he/she/they*", "he")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*him/her/their*", "him")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*his/hers/theirs*", "his")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*He/She/They*", "He")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*Him/Her/Their*", "Him")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*His/Hers/Theirs*", "His")
            );
        } else if (pronouns === "she") {
            setComment((prevComment) =>
                prevComment.replaceAll("*he/she/they*", "she")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*him/her/their*", "her")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*his/hers/theirs*", "hers")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*He/She/They*", "She")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*Him/Her/Their*", "Her")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*His/Hers/Theirs*", "Hers")
            );
        } else if (pronouns === "they") {
            setComment((prevComment) =>
                prevComment.replaceAll("*he/she/they*", "they")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*him/her/their*", "their")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*his/hers/theirs*", "theirs")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*He/She/They*", "They")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*Him/Her/Their*", "Their")
            );
            setComment((prevComment) =>
                prevComment.replaceAll("*His/Hers/Theirs*", "Theirs")
            );
        }
    };

    const handleManualChange = async (event) => {
        await setComment(event.target.value);
    };

    useEffect(() => {
        setComment("");
        let index = Object.keys(commentDict).sort();
        index.forEach((index) => {
            if (delimiter === "space") {
                setComment(
                    (prevComment) =>
                        prevComment +
                        (prevComment === "" ? "" : " ") +
                        commentDict[index]
                );
            } else if (delimiter === "enter") {
                setComment(
                    (prevComment) =>
                        prevComment +
                        (prevComment === "" ? "" : "\n\n") +
                        commentDict[index]
                );
            } else if (delimiter === "bullet") {
                setComment(
                    (prevComment) =>
                        prevComment +
                        (prevComment === "" ? "\u2022 " : "\n\u2022 ") +
                        commentDict[index]
                );
            }
        });
    }, [commentDict, delimiter, name, pronouns]);

    useEffect(() => {
        updateComment();
    }, [comment]);

    return (
        <>
            {!user && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {value && checklistItems && (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            background: `${value.data().color}`,
                            minHeight: { sm: "100vh", xs: "auto" },
                            mt: 0,
                            pb: 5,
                        }}
                    >
                        <CssBaseline />
                        <Stack
                            direction="row"
                            sx={{
                                ml: 5,
                                mt: 3,
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                width: "90%",
                            }}
                        >
                            <IconButton onClick={handleArrowClick}>
                                <ArrowBackIcon style={{ fontSize: 30 }} />
                            </IconButton>
                            <TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                value={title}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onChange={handleTitleChange}
                                onBlur={async () => {
                                    await updateDoc(
                                        doc(db, "checklists", checklistId),
                                        {
                                            title: title,
                                        }
                                    );
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                variant="standard"
                                inputProps={{
                                    style: { fontSize: 24 },
                                }}
                                sx={{ ml: 2, width: { sm: 300, xs: 200 } }}
                            />
                            <Button
                                onClick={handleClear}
                                variant="contained"
                                sx={{
                                    ml: { sm: "auto", xs: 0 },
                                    width: { sm: "auto", xs: "90%" },
                                    mt: { sm: 0, xs: 2 },
                                }}
                            >
                                Clear checkmarks
                            </Button>
                        </Stack>
                        <Container
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 2,
                                mb: -2,
                            }}
                        >
                            <Divider style={{ width: "90%" }}>
                                <IconButton onClick={handleAddItem}>
                                    <AddCircleIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Divider>
                        </Container>
                        {checklistItems &&
                            checklistItems.docs.map((item) => (
                                <ChecklistItem
                                    key={item.id}
                                    item={item}
                                    checklistId={checklistId}
                                    setCommentDict={setCommentDict}
                                    toggleClear={toggleClear}
                                />
                            ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 3,
                            mb: { sm: 0, xs: 3 },
                            px: { sm: 5, xs: 2 },
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}
                    >
                        <Box>
                            <Stack
                                direction="row"
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h5" sx={{ ml: 0, mr: 3 }}>
                                    Comment
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={delimiter}
                                    exclusive
                                    onChange={handleChange}
                                    sx={{ ml: "auto", mr: 0 }}
                                >
                                    <ToggleButton value="space">
                                        space
                                    </ToggleButton>
                                    <ToggleButton value="enter">
                                        enter
                                    </ToggleButton>
                                    <ToggleButton value="bullet">
                                        bullet
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Divider sx={{ mt: 3 }} />
                            <Stack
                                direction="column"
                                spacing={1}
                                alignItems="center"
                                justifyContent="left"
                                sx={{ width: "auto", my: 3 }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ width: "100%", mb: 1 }}
                                >
                                    Merge Fields (click to copy placeholder)
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={"*name*"}
                                        value={name}
                                        placeholder={"Enter student name..."}
                                        onChange={handleNameChange}
                                        sx={{
                                            width: "auto",
                                            flexGrow: 1,
                                            mBottom: 3,
                                        }}
                                        inputProps={{ style: { fontSize: 16 } }}
                                    />
                                    <ClickAwayListener
                                        onClickAway={handleNameTooltipClose}
                                    >
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleNameTooltipClose}
                                            open={nameOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="Copied!"
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    handleNameTooltipOpen();
                                                    navigator.clipboard.writeText(
                                                        "*name*"
                                                    );
                                                }}
                                            >
                                                *name*
                                            </Button>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </Stack>
                                <Typography variant="h6" sx={{ width: "100%" }}>
                                    Pronouns
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={pronouns}
                                        label=""
                                        onChange={handlePronounChange}
                                    >
                                        <MenuItem value={"he"}>
                                            he/him/his
                                        </MenuItem>
                                        <MenuItem value={"she"}>
                                            she/her/hers
                                        </MenuItem>
                                        <MenuItem value={"they"}>
                                            they/them/their
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <PronounButtons pronouns={pronouns} />
                            </Stack>
                            <Divider />
                            <Stack
                                direction="column"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    hiddenLabel
                                    variant="standard"
                                    multiline
                                    maxRows={100}
                                    value={comment}
                                    placeholder={
                                        "Check a comment to add it here..."
                                    }
                                    sx={{
                                        width: "100%",
                                        my: 3,
                                    }}
                                    inputProps={{ style: { fontSize: 16 } }}
                                    onChange={handleManualChange}
                                />
                                <ClickAwayListener
                                    onClickAway={handleTooltipClose}
                                >
                                    <div>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={open}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="Copied!"
                                        >
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    width: "200px",
                                                    mt: 1,
                                                    mb: 5,
                                                }}
                                                onClick={() => {
                                                    handleTooltipOpen();
                                                    navigator.clipboard.writeText(
                                                        comment
                                                    );
                                                }}
                                            >
                                                Copy to Clipboard
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { Button, ClickAwayListener, Tooltip } from "@mui/material";
import { useState } from "react";

export default function CopyTooltipButton({ child }) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied!"
            >
                <Button
                    variant="outlined"
                    onClick={() => {
                        handleTooltipOpen();
                        navigator.clipboard.writeText("*" + child + "*");
                    }}
                    sx={{
                        width: "100%",
                        textTransform: "none",
                    }}
                >
                    {child}
                </Button>
            </Tooltip>
        </ClickAwayListener>
    );
}

import { useState } from "react";
import { auth } from "../firebase/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import {
    Alert,
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

export default function ForgotPassword() {
    const [signInError, setSignInError] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data);

        sendPasswordResetEmail(auth, data.get("email"))
            .then(() => {
                // Password reset email sent!
                setSignInError(null);
                navigate("/signin");
            })
            .catch((error) => {
                setSignInError(error.message);
                // ..
            });
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    maxWidth={false}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={8}
                        component={Paper}
                        elevation={0}
                        square
                        sx={{ maxWidth: 500 }}
                    >
                        <Box
                            sx={{
                                my: { sm: 4, xs: 1 },
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{ mt: { sm: 1, xs: 0 } }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: { sm: 3, xs: 1 }, mb: 2 }}
                                >
                                    Send Reset Email
                                </Button>
                                {signInError && (
                                    <Alert severity="error">
                                        {signInError}
                                    </Alert>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Container>
            </ThemeProvider>
        </>
    );
}

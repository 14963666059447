import { Button, Grid } from "@mui/material";
import CopyTooltipButton from "./CopyTooltipButton";

export default function PronounButtons() {
    return (
        <div>
            <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"he/she/they"} />
                </Grid>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"him/her/their"} />
                </Grid>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"his/hers/theirs"} />
                </Grid>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"He/She/They"} />
                </Grid>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"Him/Her/Their"} />
                </Grid>
                <Grid item xs={4}>
                    <CopyTooltipButton child={"His/Hers/Theirs"} />
                </Grid>
            </Grid>
        </div>
    );
}

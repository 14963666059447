import { loadStripe } from "@stripe/stripe-js";

let stripePromise = null;

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe(
            "pk_live_51KhhGvAWMfCjy3CFfelYxwgAhFLXEtWbwxYMuZ5k2FSVjkXKggwkaQok7u4S55753kW6yqMnfN6oQnydzKwibzFx00ic2k2dFV"
        );
    }
    return stripePromise;
};

export default initializeStripe;

import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useCollection } from "react-firebase-hooks/firestore";
import {
    doc,
    getDoc,
    collection,
    addDoc,
    orderBy,
    query,
    serverTimestamp,
    where,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebaseConfig";
import ChecklistCard from "../components/ChecklistCard";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createCheckoutSession } from "../stripe/createCheckoutSession";
import usePremiumStatus from "../stripe/usePremiumStatus";
import { TextField } from "@mui/material";

const colors = [
    "#FFADAD",
    "#FFD6A5",
    "#FDFFB6",
    "#CAFFBF",
    "#9BF6FF",
    "#A0C4FF",
    "#BDB2FF",
    "#FFC6FF",
    "#FFFFFC",
];

export default function Dashboard() {
    const [user, userLoading, userError] = useAuthState(auth);

    const navigate = useNavigate();

    useEffect(async () => {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.data().agree !== true) {
            navigate("/agree");
        }
    }, []);

    const userIsPremium = usePremiumStatus(user);
    const [upgradeLoading, setUpgradeLoading] = useState(false);
    const [value, loading, error] = useCollection(
        query(
            collection(db, "checklists"),
            where("usersArray", "array-contains", user.uid)
            //orderBy("title")
        ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const handleClick = async () => {
        const random = Math.floor(Math.random() * colors.length);
        await addDoc(collection(db, "checklists"), {
            title: "...Click and type to edit...",
            usersArray: [user.uid],
            owner: user.uid,
            created: serverTimestamp(),
            updated: serverTimestamp(),
            startingIndex: 100,
            commentsArray: [],
            color: colors[random],
        });
    };

    const [backdropOpen, setBackdropOpen] = useState(false);

    const [search, setSearch] = useState("");

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CssBaseline />
            <Container
                maxWidth={false}
                sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {user && !userIsPremium && !upgradeLoading && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setUpgradeLoading(true);
                            setBackdropOpen(true);
                            createCheckoutSession(user.uid);
                        }}
                        sx={{ mt: 2, backgroundColor: "#0ED145" }}
                    >
                        Upgrade Account
                    </Button>
                )}
                {user && !userIsPremium && upgradeLoading && (
                    <Button
                        variant="contained"
                        disabled
                        sx={{ mt: 2, backgroundColor: "#0ED145" }}
                    >
                        Loading...
                    </Button>
                )}
                {user && userIsPremium && (
                    <>
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            sx={{ mt: 2, backgroundColor: "#0ED145" }}
                        >
                            Add checklist
                        </Button>
                        <TextField
                            id="outlined-search"
                            label="Search"
                            value={search}
                            onChange={handleChange}
                            type="search"
                            sx={{ mt: 3, width: 275 }}
                        />
                    </>
                )}
                {loading && <Typography sx={{ mt: 3 }}>Loading...</Typography>}
                {value && (
                    <Container
                        maxWidth={false}
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignContent: "flex-",
                        }}
                    >
                        {value.docs.map((checklist, index) => (
                            <Box key={checklist.id} style={{ height: "300px" }}>
                                <ChecklistCard
                                    checklist={checklist}
                                    userIsPremium={userIsPremium}
                                    search={search}
                                />
                            </Box>
                        ))}
                    </Container>
                )}
            </Container>
        </>
    );
}

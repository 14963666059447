// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD5zrIl3YJWreTftAnl0U3dGBaZNEyefwc",
    authDomain: "checklist-app-c793e.firebaseapp.com",
    projectId: "checklist-app-c793e",
    storageBucket: "checklist-app-c793e.appspot.com",
    messagingSenderId: "250796579248",
    appId: "1:250796579248:web:7186b3617f48fd4973de75",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore();

export { app, auth, db };

import { Typography } from "@mui/material";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { Avatar, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

export default function UserRow({ userId, isOwner, checklistId }) {
    const [user, userLoading, userError] = useDocumentDataOnce(
        doc(db, "users", userId)
    );

    if (userLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ width: "100%" }}
            >
                <Avatar src={user?.photoURL} />
                <Typography>{user?.displayName}</Typography>
                <Typography>({user?.email})</Typography>
                {isOwner === true && (
                    <IconButton
                        onClick={() => {
                            updateDoc(doc(db, "checklists", checklistId), {
                                usersArray: arrayRemove(userId),
                            });
                        }}
                        sx={{ ml: "auto" }}
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Stack>
        </>
    );
}

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/firebaseConfig";

import SignInSide from "./pages/SignInSide";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar";
import Checklist from "./pages/Checklist";
import Terms from "./pages/Terms";
import CreateAccount from "./pages/CreateAccount";
//import UserVerify from "./pages/UserVerify";
import ForgotPassword from "./pages/ForgotPassword";
import AgreeTerms from "./pages/AgreeTerms";

import "./App.css";

function App() {
    const [user, loading, error] = useAuthState(auth);

    return (
        <div className="App">
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                {!user && !loading && <LandingPage />}
                                {user && !loading && <Dashboard />}
                            </>
                        }
                    />
                    <Route
                        path="/agree"
                        element={
                            <>
                                {user && <AgreeTerms />}
                                {!user && <LandingPage />}
                            </>
                        }
                    />
                    <Route path="/signin" element={<SignInSide />} />
                    <Route path="/checklist/:id" element={<Checklist />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/createaccount" element={<CreateAccount />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

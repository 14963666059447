import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid) {
    const checkoutSessionRef = await addDoc(
        collection(db, "users", uid, "checkout_sessions"),
        {
            price: "price_1KwKErAWMfCjy3CFklK8QUaV",
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            automatic_tax: {
                enabled: true,
            },
            allow_promotion_codes: true,
            discounts: {
                promotion_code: "promo_1KxedvAWMfCjy3CFZPbGRGdQ",
            },
        }
    );

    onSnapshot(checkoutSessionRef, async (snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
            const stripe = await getStripe();
            stripe.redirectToCheckout({ sessionId });
        }
    });
}

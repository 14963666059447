import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Alert } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BackgroundImage from "../assets/teacher-checklist.jpg";
import GoogleButton from "react-google-button";
import {
    signInWithPopup,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    doc,
    getDoc,
    setDoc,
    serverTimestamp,
    updateDoc,
    collection,
    addDoc,
} from "firebase/firestore";
import { useState } from "react";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" to="/">
                HarperTech LLC
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createTheme();

const provider = new GoogleAuthProvider();

const colors = [
    "#FFADAD",
    "#FFD6A5",
    "#FDFFB6",
    "#CAFFBF",
    "#9BF6FF",
    "#A0C4FF",
    "#BDB2FF",
    "#FFC6FF",
    "#FFFFFC",
];

export default function SignInSide() {
    const [signInError, setSignInError] = useState(null);

    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        signInWithEmailAndPassword(
            auth,
            data.get("email"),
            data.get("password")
        )
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // ...
                navigate("/");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setSignInError(errorMessage);
            });
    };

    const handleGoogleSignIn = (event) => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...

                const docRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(docRef);

                const docExists = docSnap.exists();

                if (!docExists) {
                    const user_data = {
                        displayName: user.displayName,
                        email: user.email,
                        phoneNumber: user.phoneNumber,
                        photoURL: user.photoURL,
                        userId: user.uid,
                        firstLogin: serverTimestamp(),
                        lastLogin: serverTimestamp(),
                    };

                    await setDoc(docRef, user_data, {
                        merge: true,
                    });
                    const random = Math.floor(Math.random() * colors.length);
                    await addDoc(collection(db, "checklists"), {
                        title: "...Click and type to edit...",
                        userId: user.uid,
                        created: serverTimestamp(),
                        updated: serverTimestamp(),
                        owner: user.uid,
                        usersArray: [user.uid],
                        startingIndex: 100,
                        color: colors[random],
                    });
                    navigate("/");
                } else {
                    await updateDoc(docRef, { lastLogin: serverTimestamp() });
                    if (docSnap.data().agree === true) {
                        navigate("/");
                    } else {
                        navigate("/agree");
                    }
                }
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential =
                    GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth={false}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={8}
                        component={Paper}
                        elevation={0}
                        square
                        sx={{ maxWidth: 500 }}
                    >
                        <Box
                            sx={{
                                my: { sm: 4, xs: 1 },
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{ mt: { sm: 1, xs: 0 } }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="remember"
                                            color="primary"
                                        />
                                    }
                                    label="Remember me"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: { sm: 3, xs: 1 }, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                                {signInError && (
                                    <Alert severity="error">
                                        {signInError}
                                    </Alert>
                                )}
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/forgot" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            href="/createaccount"
                                            variant="body2"
                                        >
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 1 }}>OR</Divider>
                                <Container
                                    sx={{
                                        mb: 1,
                                        display: "d-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <GoogleButton
                                        onClick={(e) => {
                                            handleGoogleSignIn(e);
                                        }}
                                    />
                                </Container>
                                <Copyright />
                            </Box>
                        </Box>
                    </Grid>
                </Container>
            </ThemeProvider>
        </>
    );
}

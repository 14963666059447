import "./LandingPage.css";
import LandingImageRight from "../assets/Transparent.png";
import LandingImageLeft from "../assets/landing_page_left.PNG";
import ReactPlayer from "react-player/lazy";
import WelcomeVideo from "../assets/Welcome.mp4";

import {
    autocompleteClasses,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/StarBorder";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useNavigate } from "react-router-dom";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            width="100%"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" to="/">
                HarperTech LLC
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const tiers = [
    {
        title: "Free",
        price: "0",
        description: [
            "1 checklist",
            "Customizable title",
            "Unlimited checklist items",
            "Unlimited comment length",
        ],
        buttonText: "Sign up for free",
        buttonVariant: "outlined",
    },
    {
        title: "Premium",
        price: "3",
        description: [
            "Unlimited checklists",
            "Customizable titles",
            "Unlimited checklist items",
            "Unlimited comment length",
            "Search bar for finding lists",
            "Share lists with other users",
        ],
        buttonText: "Get started",
        buttonVariant: "contained",
    },
];

export default function LandingPage() {
    const navigate = useNavigate();

    return (
        <div className="landing-page">
            <CssBaseline />
            <Container
                maxWidth="lg"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Grid
                    container
                    sx={{
                        height: "auto",
                        mt: { sm: 3, xs: 0 },
                    }}
                >
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            px: { sm: 2, xs: 2 },
                            mt: { sm: 0, xs: 1 },
                            mb: { sm: 0, xs: 1 },
                        }}
                    >
                        <img
                            src={LandingImageLeft}
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            px: { sm: 0, xs: 1 },
                        }}
                    >
                        <img
                            src={LandingImageRight}
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 4,
                    py: [1, 0],
                }}
            ></Container>
            <Container maxWidth="md" component="main" sx={{ my: 3 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    sx={{ mt: 3 }}
                >
                    See it in Action
                </Typography>
                <ReactPlayer
                    url={WelcomeVideo}
                    width="100%"
                    height="100%"
                    controls
                />
            </Container>
            <Container
                disableGutters
                maxWidth="sm"
                component="main"
                sx={{ pt: 2, pb: 6 }}
            >
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Pricing
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Whether you want one ultimate checklist for all your
                    comments or several checklists to organize your feedback,
                    there's a plan for you.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: "center" }}
                                    action={
                                        tier.title === "Pro" ? (
                                            <StarIcon />
                                        ) : null
                                    }
                                    subheaderTypographyProps={{
                                        align: "center",
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === "light"
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent sx={{ height: 250 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            component="h2"
                                            variant="h3"
                                            color="text.primary"
                                        >
                                            ${tier.price}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                        >
                                            /mo
                                        </Typography>
                                    </Box>
                                    {tier.description.map((line) => (
                                        <Typography
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant={tier.buttonVariant}
                                        onClick={() => {
                                            navigate("/createaccount");
                                        }}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [1, 0],
                }}
            ></Container>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justfifyContent: "center",
                    flexDirection: "column",
                    mt: 2,
                    mb: 2,
                }}
            >
                <Copyright />
                <Typography
                    variant="body1"
                    color="text.secondary"
                    align="center"
                    width="100%"
                >
                    For support, email{" "}
                    <Link color="inherit" href="mailto:support@harpertech.io">
                        support@harpertech.io
                    </Link>
                    {"."}
                </Typography>
            </Container>
        </div>
    );
}

import {
    Box,
    Checkbox,
    Container,
    Divider,
    IconButton,
    Stack,
    Typography,
    TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Delete from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState, useEffect } from "react";
import {
    collection,
    addDoc,
    updateDoc,
    doc,
    deleteDoc,
    serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

export default function ChecklistItem({
    item,
    checklistId,
    setCommentDict,
    toggleClear,
}) {
    const [user, userLoading, userError] = useAuthState(auth);
    const [content, setContent] = useState("");
    const [index, setIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(null);
    const [checked, setChecked] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setContent(item.data().content);
        setIndex(item.data().index);
        setNextIndex(item.data().nextIndex);
    }, []);

    useEffect(() => {
        setChecked(false);
    }, [toggleClear]);

    const handleCheckedChange = (event) => {
        setChecked(event.target.checked);
        setCommentDict((prevCommentDict) => {
            if (event.target.checked) {
                return { ...prevCommentDict, [index]: content };
            } else if (!event.target.checked) {
                let dict = {};
                let keys = Object.keys(prevCommentDict);
                keys.forEach((key) => {
                    if (Number(key) !== index) {
                        dict[key] = prevCommentDict[key];
                    }
                });
                return dict;
            }
        });
    };

    const handleContentChange = async (event) => {
        await setContent(event.target.value);
    };

    const handleClose = async () => {
        setIsEditable(false);
        await updateDoc(doc(db, "checklist-items", item.id), {
            content: content,
            lastEdit: serverTimestamp(),
        });
        if (checked) {
            setCommentDict((prevCommentDict) => {
                return { ...prevCommentDict, [index]: content };
            });
        }
    };

    const handleDelete = async () => {
        await deleteDoc(doc(db, "checklist-items", item.id));
        if (checked) {
            setCommentDict((prevCommentDict) => {
                let dict = {};
                let keys = Object.keys(prevCommentDict);
                keys.forEach((key) => {
                    if (Number(key) !== index) {
                        dict[key] = prevCommentDict[key];
                    }
                });
                return dict;
            });
        }
    };

    const handleAddItem = async () => {
        let newIndex = (index + nextIndex) / 2;
        await addDoc(collection(db, "checklist-items"), {
            checklistId: checklistId,
            content: "Double click here or click the edit icon to change...",
            index: newIndex,
            nextIndex: nextIndex,
            lastEdit: serverTimestamp(),
        });
        await updateDoc(doc(db, "checklist-items", item.id), {
            nextIndex: newIndex,
        });
        setNextIndex(newIndex);
    };

    return (
        <div>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    ml: { sm: 7.5, xs: 5 },
                    my: 2,
                    width: "45vw",
                    display: "flex",
                    alignItems: "flex-start",
                }}
            >
                <Checkbox
                    checked={checked}
                    onChange={handleCheckedChange}
                    inputProps={{ "aria-label": "controlled" }}
                />
                <Box>
                    {!isEditable && (
                        <Typography
                            onDoubleClick={() => setIsEditable(true)}
                            sx={{ width: "45vw", fontSize: 16, mt: 1 }}
                        >
                            {content}
                        </Typography>
                    )}
                    {isEditable && (
                        <TextField
                            id="standard-multiline-static"
                            multiline
                            maxRows={100}
                            value={content}
                            sx={{
                                width: "45w",
                                minWidth: "45vw",
                                maxWidth: "45vw",
                                fontSize: 16,
                            }}
                            onChange={handleContentChange}
                            variant={`${isEditable ? "filled" : "standard"}`}
                            onBlur={async () => {
                                await updateDoc(
                                    doc(db, "checklist-items", item.id),
                                    {
                                        content: content,
                                        lastEdit: serverTimestamp(),
                                    }
                                );
                            }}
                        />
                    )}
                </Box>
                {!isEditable && (
                    <IconButton onClick={() => setIsEditable(true)}>
                        <EditIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                )}
                {isEditable && (
                    <IconButton onClick={handleClose}>
                        <DoneIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                )}
                <IconButton onClick={handleDelete}>
                    <Delete sx={{ fontSize: 24, ml: -3 }} />
                </IconButton>
            </Stack>
            <Container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 0,
                    my: -2,
                }}
            >
                <Divider style={{ width: "90%" }}>
                    <IconButton onClick={handleAddItem}>
                        <AddCircleIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                </Divider>
            </Container>
        </div>
    );
}

import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import Delete from "@mui/icons-material/Delete";
import PaletteIcon from "@mui/icons-material/Palette";
import { Popover, TextField, Container, FormControl } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import ColorPalette from "./ColorPalette";

import {
    useCollection,
    useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import {
    doc,
    deleteDoc,
    getDocs,
    updateDoc,
    collection,
    query,
    orderBy,
    where,
    limit,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, app } from "../firebase/firebaseConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";

import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player/lazy";
import WelcomeVideo from "../assets/Welcome.mp4";
import { useLocalStorage } from "../helpers/useLocalStorage";
import UserRow from "./UserRow";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pt: 1,
    px: 1,
};

const palette = {
    pink: "#FFADAD",
    champagne: "#FFD6A5",
    yellow: "#FDFFB6",
    macha: "#CAFFBF",
    lightblue: "#9BF6FF",
    blue: "#A0C4FF",
    purple: "#BDB2FF",
    mauve: "#FFC6FF",
    white: "#FFFFFC",
};

export default function ChecklistCard({ checklist, userIsPremium, search }) {
    const [user, userLoading, userError] = useAuthState(auth);

    const [value, loading, error] = useCollection(
        query(
            collection(db, "checklist-items"),
            where("checklistId", "==", checklist.id),
            orderBy("index"),
            limit(5)
        ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [color, setColor] = useState(checklist.data().color);
    const [title, setTitle] = useState(checklist.data().title);

    const navigate = useNavigate();

    const handleTitleChange = async (event) => {
        await setTitle(event.target.value);
    };

    const handleDelete = async () => {
        await deleteDoc(doc(db, "checklists", checklist.id));
    };

    const handleColorSelect = async (event) => {
        setColor(palette[event]);
        setAnchorEl(null);
        await updateDoc(doc(db, "checklists", checklist.id), {
            color: palette[event],
        });
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePaletteClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePaletteClose = () => {
        setAnchorEl(null);
    };

    const handleExpandClick = async () => {
        await localStorage.setItem("checklistTitle", JSON.stringify(title));
        navigate(`/checklist/${checklist.id}`);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [modalOpen, setModalOpen] = useLocalStorage("modalOpen", true);

    const handleModalClose = () => {
        localStorage.setItem("modalOpen", false);
        setModalOpen(false);
    };
    const handleModalOpen = () => setModalOpen(true);

    const [shareOpen, setShareOpen] = useState(false);

    const handleShare = () => setShareOpen(true);

    const handleShareClose = () => {
        setShareOpen(false);
    };

    const functions = getFunctions(app, "us-central1");
    const shareChecklist = httpsCallable(functions, "shareChecklist");

    return (
        <>
            {!userIsPremium && (
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-not-premium"
                    aria-describedby="modal-user-not-premium"
                >
                    <Box sx={style}>
                        <ReactPlayer
                            url={WelcomeVideo}
                            width="100%"
                            height="100%"
                            controls
                        />
                    </Box>
                </Modal>
            )}
            <Modal
                open={shareOpen}
                onClose={handleShareClose}
                aria-labelledby="modal-share"
                aria-describedby="modal-share-screen"
            >
                <Box sx={{ ...style, width: "auto", p: 2 }}>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row">
                            <Typography variant="h5">Share</Typography>
                            <Button
                                variant="contained"
                                onClick={handleShareClose}
                                sx={{ ml: "auto", backgroundColor: "#0ED145" }}
                            >
                                Close
                            </Button>
                        </Stack>
                        {checklist.data().usersArray.map((userId) => (
                            <>
                                {userId !== user.uid && (
                                    <UserRow
                                        key={userId}
                                        userId={userId}
                                        isOwner={
                                            checklist.owner === user.userId
                                        }
                                        checklistId={checklist.id}
                                    />
                                )}
                            </>
                        ))}
                        <form
                            onSubmit={async (event) => {
                                event.preventDefault();
                                console.log(event.target.email.value);
                                const email = event.target.email.value;

                                const result = await shareChecklist({
                                    email: email,
                                    checklistId: checklist.id,
                                    usersArray: checklist.data().usersArray,
                                });
                                if (!result.data.success) {
                                    alert("User not found.");
                                }
                            }}
                        >
                            <FormControl fullWidth>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="end"
                                >
                                    <Button variant="outlined" type="submit">
                                        Add
                                    </Button>
                                    <TextField
                                        id="email"
                                        variant="standard"
                                        label="Email Address"
                                        type="email"
                                        sx={{ width: "80%" }}
                                    />
                                </Stack>
                            </FormControl>
                        </form>
                    </Stack>
                </Box>
            </Modal>
            <Box
                sx={{
                    p: 2,
                    display: `${
                        !title.toLowerCase().includes(search.toLowerCase())
                            ? "none"
                            : ""
                    }`,
                }}
            >
                <Card
                    elevation={5}
                    sx={{
                        background: `${color}`,
                        width: "275px",
                        height: "275px",
                    }}
                >
                    <CardContent>
                        <TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            value={title}
                            onFocus={(e) => {
                                e.target.select();
                            }}
                            onChange={handleTitleChange}
                            onKeyPress={async (e) => {
                                if (e.key === "Enter") {
                                    await updateDoc(
                                        doc(db, "checklists", checklist.id),
                                        {
                                            title: title,
                                        }
                                    );
                                }
                            }}
                            onBlur={async () => {
                                await updateDoc(
                                    doc(db, "checklists", checklist.id),
                                    {
                                        title: title,
                                    }
                                );
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                            variant="standard"
                            inputProps={{
                                style: { fontSize: 20, textAlign: "center" },
                            }}
                        />
                        <Typography
                            sx={{
                                mb: 1.5,
                                height: "150px",
                                overflow: "hidden",
                            }}
                            color="text.secondary"
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            {loading && <span>Loading...</span>}
                            {error && <span>{error}</span>}
                            {value &&
                                value.docs.map((item) => (
                                    <Typography key={item.id} sx={{ m: 1 }}>
                                        {item.data().content}
                                    </Typography>
                                ))}
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mx: "auto",
                                mb: -2,
                                pb: 0,
                            }}
                            spacing={0}
                        >
                            <IconButton
                                onClick={handleExpandClick}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                <OpenInFullIcon />
                            </IconButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePaletteClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box
                                    width={105}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#EEEEEE",
                                    }}
                                >
                                    <ColorPalette
                                        palette={palette}
                                        onSelect={(e) => {
                                            handleColorSelect(e);
                                        }}
                                    />
                                </Box>
                            </Popover>
                            <IconButton
                                onClick={handlePaletteClick}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                <PaletteIcon />
                            </IconButton>
                            {userIsPremium && (
                                <IconButton
                                    onClick={handleShare}
                                    onTouchStart={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                >
                                    <ShareIcon />
                                </IconButton>
                            )}
                            {userIsPremium && checklist.owner === user.userId && (
                                <IconButton
                                    onClick={handleDelete}
                                    onTouchStart={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                >
                                    <Delete />
                                </IconButton>
                            )}
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}

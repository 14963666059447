import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Container, Stack, Toolbar, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Image from "../assets/checklist-icon.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, app } from "../firebase/firebaseConfig";
import { signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/checklist-icon.png";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import usePremiumStatus from "../stripe/usePremiumStatus";
import { getFunctions, httpsCallable } from "firebase/functions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player/lazy";
import WelcomeVideo from "../assets/Welcome.mp4";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pt: 1,
    px: 1,
};

function Navbar() {
    const [user, loading, error] = useAuthState(auth);
    const userIsPremium = usePremiumStatus(user);

    const functionLocation = "us-central1";

    const functions = getFunctions(app, "us-central1");

    const handleManageSubClick = async () => {
        setBackdropOpen(true);

        const functionRef = httpsCallable(
            functions,
            "ext-firestore-stripe-payments-createPortalLink"
        );

        const { data } = await functionRef({
            returnUrl: window.location.origin,
        });
        window.location.assign(data.url);
    };

    const navigate = useNavigate();

    const handleSignOut = (event) => {
        event.preventDefault();
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                navigate("/signin");
            })
            .catch((error) => {
                // An error happened.
            });
    };
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElNav(null);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleModalOpen = () => setModalOpen(true);

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ReactPlayer
                        url={WelcomeVideo}
                        width="100%"
                        height="100%"
                        controls
                    />
                </Box>
            </Modal>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                mt: -0.5,
                                display: { xs: "none", md: "flex" },
                                fontSize: { sm: "30px", xs: "24px" },
                            }}
                        >
                            <Link
                                to="/"
                                className="navbar-title"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    onClick={() => navigate("/")}
                                    src={Logo}
                                    style={{
                                        width: 50,
                                        marginRight: 5,
                                        marginTop: 3,
                                    }}
                                />
                                Clicks to Comments
                            </Link>
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link
                                        to="/"
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                    >
                                        <Typography textAlign="center">
                                            Home
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                {!user && (
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Link
                                            to="/signin"
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                        >
                                            <Typography textAlign="center">
                                                Sign in
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                )}
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link
                                        to="/terms"
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                    >
                                        <Typography textAlign="center">
                                            Terms
                                        </Typography>
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Typography
                            variant="subitle1"
                            noWrap
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            <Link
                                to="/"
                                className="navbar-title"
                                sx={{ fontSize: "50px" }}
                            >
                                Clicks to Comments
                            </Link>
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                                justifyContent: "flex-end",
                            }}
                        >
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                    marginRight: 15,
                                }}
                            >
                                <Typography textAlign="center">Home</Typography>
                            </Link>
                            {!user && (
                                <Link
                                    to="/signin"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                        marginRight: 15,
                                    }}
                                >
                                    <Typography textAlign="center">
                                        Sign in
                                    </Typography>
                                </Link>
                            )}
                            <Link
                                to="/terms"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                    marginRight: 15,
                                }}
                            >
                                <Typography textAlign="center">
                                    Terms
                                </Typography>
                            </Link>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            {user && (
                                <div>
                                    <Tooltip title="Open settings">
                                        <IconButton
                                            onClick={handleOpenUserMenu}
                                            sx={{ p: 0 }}
                                        >
                                            <Avatar
                                                src={user.photoURL}
                                                alt={user.displayName}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: "45px" }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {userIsPremium && (
                                            <MenuItem
                                                onClick={handleCloseUserMenu}
                                            >
                                                <Button
                                                    onClick={
                                                        handleManageSubClick
                                                    }
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "black",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Manage Subscription
                                                </Button>
                                            </MenuItem>
                                        )}
                                        <MenuItem onClick={handleCloseUserMenu}>
                                            <Button
                                                onClick={handleModalOpen}
                                                sx={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    width: "100%",
                                                }}
                                            >
                                                Help
                                            </Button>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                handleCloseNavMenu();
                                                handleSignOut(e);
                                                handleCloseUserMenu();
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    width: "100%",
                                                }}
                                            >
                                                Sign out
                                            </Button>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}
                            {!user && (
                                <Avatar
                                    alt="User"
                                    sx={{ visibility: "hidden" }}
                                />
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
export default Navbar;
